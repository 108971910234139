//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'DefaultLayout',
  components: {
    primaryNavigation: () => import('@/components/layouts/navbar.vue'),
    sideBar: () => import('@/components/layouts/sidebar.vue'),
    sideBarLogin: () => import('@/components/layouts/sidebarLogin.vue'),
    sideBarProfile: () => import('@/components/layouts/sidebarProfile.vue'),
    contentLoader: () =>
      import('@/components/sections/global/contentLoader.vue'),
    PrimaryFooter: () => import('@/components/layouts/footer.vue'),
    footerBottom: () => import('@/components/layouts/footerbottom.vue'),
    navbarResponsive: () => import('@/components/layouts/navbarResponsive.vue'),
    FollowerCursor: () => import('@/components/general/FollowerCursor.vue'),
    notificationModal: () => import('@/components/sections/global/notification'),
  },
  data() {
    return {
      metaData: null,
      activeLang: null,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
      menuOpened: (state) => state.isMenuOpened,
      showNotification: (state) => state.showNotification,
    }),
  },
  watch: {
    isLoading: {
      handler() {
        if (this.menuOpened) {
          document.body.classList.add('js-kill-scroll')
        } else {
          document.body.classList.remove('js-kill-scroll')
        }
      },
    },
  },
}
