import { AUTH_URL } from '../helpers/url'

export const state = () => ({
  links: null,
  tippsDetailUrl: '',
  isLoading: false,
  isMenuOpened: false,
  isLoginOpened: false,
  isProfileOpened: false,
  showNotification: false,
  partners: [],
  tippsFilters: null,
  company: null,

  favourites: []
})

export const mutations = {
  SET_MENU(state, payload) {
    state.isMenuOpened = payload
  },
  SET_NOTIFICATION(state, payload) {
    state.showNotification = payload
  },
  SET_LOGIN(state, payload) {
    state.isLoginOpened = payload
  },
  SET_PROFILE(state, payload) {
    state.isProfileOpened = payload
  },
  SET_GLOBAL_LINKS(state, payload) {
    state.links = payload
  },
  SET_TRIP_DETAIL_URL(state, payload) {
    state.tippsDetailUrl = payload
  },
  SET_GLOBAL_LOADING(state, payload) {
    state.isLoading = payload
  },
  SET_PARTNERS(state, payload) {
    state.partners = payload
  },
  SET_TIPPS_FILTERS(state, payload) {
    state.tippsFilters = payload
  },
  SET_COMPANY_INFO(state, value) {
    state.company = value
  },
  SET_FAVOURITES(state, value) {
    state.favourites = value
  },
  UPDATE_FAVOURITE(state, { identifier, flag }) {
    state.favourites[
      state.favourites.findIndex((x) => x.identifier === identifier)
    ].favourite = flag
  },
}

export const getters = {
  getFavourites: (state) => state.favourites,
  getFavouritebyIdentifier: (state) => (identifier) =>
    state.favourites.find((x) => x.identifier === identifier),
}

export const actions = {
  getContentDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const defaultLanguage = "de"
      const supportedLanguages = ["de", "en"]
      const language = payload.url.split('/')[1]

      if (this.$i18n) {
        this.$i18n.locale = supportedLanguages.includes(language) ? language : defaultLanguage;
      }

      this.$axios
        .$get(payload.url, payload)
        .then((res) => {
          commit('SET_COMPANY_INFO', res.page.company[0])
          resolve(res)
        })
        .catch((err) => {
          reject(err)
          // console.log("Fehler aufgetreten index!!:", err);
          //
          // // Falls der Status 404 ist und trotzdem hilfreiche Fehlerdaten enthält
          // if (err.response && err.response.status === 404) {
          //   const errorData = err.response.data;
          //   if (errorData && errorData.page) {
          //     console.log("✅ Fehlerseite gefunden, Content wird weitergegeben:", errorData);
          //
          //     // Extrahiere den Inhalt der Fehlerseite und setze ihn als Fallback
          //     commit('SET_COMPANY_INFO', errorData.page.company ? errorData.page.company[0] : []);
          //     resolve(errorData); // Fehlerseite als "normale" Antwort zurückgeben
          //   } else {
          //     console.log("❌ Fehlerseite ohne spezifische Daten, Standard-Content wird gesetzt");
          //     // Keine brauchbaren Daten im Fehler, setze leere Fallback-Daten oder Standard-Inhalte
          //     const fallbackData = { page: { company: [] }, content: { text: "Standard-Fehlerseite" } };
          //     resolve(fallbackData); // Rückgabe der Fallback-Daten
          //   }
          // } else {
          //   console.log("❌ Fehler bei der Anfrage, der Fehler wird weitergegeben.");
          //   reject(err);  // Bei anderen Fehlern wird der Fehler durchgereicht
          // }
        })
        .finally(() => {
          setTimeout(() => {
            commit('SET_GLOBAL_LOADING', false)
          }, 1000)
        })
    })
  },
  async setFavourite({ commit, getters }, payload) {
    if (getters.getFavouritebyIdentifier(payload.identifier)) {
      commit('UPDATE_FAVOURITE', payload)
    }

    if (payload.flag) {
      await this.$axios.post(
        AUTH_URL + '/api/favourites/' + payload.identifier,
        {},
        { headers: { Authorization: this.$auth.strategy.token.get() } }
      )
      if (payload.showNotification) {
        commit('SET_NOTIFICATION', true)
        setTimeout(() => {
          commit('SET_NOTIFICATION', false)
        }, 3000)
      }
    } else {
      await this.$axios.delete(
        AUTH_URL + '/api/favourites/' + payload.identifier,
        { headers: { Authorization: this.$auth.strategy.token.get() } }
      )
    }
  },

  async loadFavourites({ commit }) {
    try {
      const { data } = await this.$axios.get(AUTH_URL + '/api/favourites', {
        headers: { Authorization: this.$auth.strategy.token.get() },
      })
      commit('SET_FAVOURITES', data.data)
    } catch (e) {
      /* unauth */
    }
  },

  getLinks({ commit }, payload) {
    const apiUrl = `${payload.url}?type=834`;
    return new Promise((resolve, reject) => {
      this.$axios
        .$get( apiUrl)
        .then((res) => {
          commit('SET_GLOBAL_LINKS', res)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setTimeout(() => {
            commit('SET_GLOBAL_LOADING', false)
          }, 1000)
        })
    })
  },
}
