export default ({ app }) => {
  // Every time the route changes (fired on initialization too)
  app.router.afterEach((to, from) => {
    /* console.log(to, from) */
    if (
      from.name !== null &&
      ((from.path !== '/rheinwelten/ukv' && to.path === '/rheinwelten/ukv') ||
        (from.path !== '/rheinwelten/experience' &&
          to.path === '/rheinwelten/experience') ||
        (from.path !== '/datenschutz' && to.path === '/datenschutz') ||
        (from.path !== '/en/privacy-policy' && to.path === '/en/privacy-policy') ||
        (from.path !== '/entdecken/rheinwelten-memory' && to.path === '/entdecken/rheinwelten-memory') ||
        (from.path !== '/social-login' && to.path === '/social-login') ||
        to.path.split('/').includes('house'))
    ) {
      location.reload()
    }
  })
}
